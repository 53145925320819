import { PaymentStatusEnum } from '@boels-shared/enums/payment-status.enum';

export const DEMO_ACCOUNT_MOCK_URLS: Record<any, any> = {
  // Make sure left part matches the request URL endpoint.
  '/users': {
    name: 'Demo Account (not real data)',
    profile: 'MyBoels - Read Only',
    accounts: [
      {
        debtorNumber: '99993024',
        name: 'Demo Debtor account',
        nameExtraInfo: 'Demo Debtor DC, international',
      },
    ],
  },
  '/invoices/query': {
    invoices: [
      // start invoices contractnumber 3110116336
      {
        id: '4N7igdyThudpztrlZEWSQN6YGuYlfhPQ',
        invoiceNumber: '5947375878',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-02-07',
        debtorNumber: '99993024',
        orderedBy: 'Liam Smith',
        dueDate: '2023-11-26',
        grossAmount: 55.7,
        vatAmount: 10.64,
        netAmount: 61.35,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Overdue,
        openAmount: null,
        reference: '79624-bioboxes.92.2023-12-08.2023',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '3110116336',
          },
        ],
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: '4N7igdyThudpztrlZEWSQN6YGuYlfhPQ',
        invoiceNumber: '5947375878',
        invoiceType: 'Credit Note',
        invoiceDate: '2023-02-18',
        debtorNumber: '99993024',
        orderedBy: 'Liam Smith',
        dueDate: '2023-11-26',
        grossAmount: -55.7,
        vatAmount: -10.64,
        netAmount: -61.35,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Completed,
        openAmount: null,
        reference: '79624-bioboxes.92.2023-12-08.2023',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '3110116336',
          },
        ],
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: '4N7igdyThudpztrlZEWSQN6YGuYlfhPQ',
        invoiceNumber: '7564760962',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-02-18',
        debtorNumber: '99993024',
        orderedBy: 'Liam Smith',
        dueDate: '2024-11-26',
        grossAmount: 55.7,
        vatAmount: 10.64,
        netAmount: 61.35,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Open,
        openAmount: null,
        reference: '79624-bioboxes.92.2023-12-08.2023',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '3110116336',
          },
        ],
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: '4N7igdyThudpztrlZEWSQN6YGuYlfhPQ',
        invoiceNumber: '2072791446',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-03-19',
        debtorNumber: '99993024',
        orderedBy: 'Liam Smith',
        dueDate: '2023-11-26',
        grossAmount: 55.7,
        vatAmount: 10.64,
        netAmount: 61.35,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Overdue,
        openAmount: null,
        reference: '79624-bioboxes.92.2023-12-08.2023',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '3110116336',
          },
        ],
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: '4N7igdyThudpztrlZEWSQN6YGuYlfhPQ',
        invoiceNumber: '4729316910',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-03-29',
        debtorNumber: '99993024',
        orderedBy: 'Liam Smith',
        dueDate: '2024-11-26',
        grossAmount: 55.7,
        vatAmount: 10.64,
        netAmount: 61.35,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Open,
        openAmount: null,
        reference: '79624-bioboxes.92.2023-12-08.2023',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '3110116336',
          },
        ],
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: '4N7igdyThudpztrlZEWSQN6YGuYlfhPQ',
        invoiceNumber: '3478604119',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-04-20',
        debtorNumber: '99993024',
        orderedBy: 'Liam Smith',
        dueDate: '2023-11-26',
        grossAmount: 55.7,
        vatAmount: 10.64,
        netAmount: 61.35,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Overdue,
        openAmount: null,
        reference: '79624-bioboxes.92.2023-12-08.2023',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '3110116336',
          },
        ],
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      // end invoices contractnumber 3110116336

      // start invoices contractnumber 1447564012
      {
        id: '6PKGwxgUcvRKVyJSVtxhKJblonBrTksJ',
        invoiceNumber: '5409030090',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-09-30',
        debtorNumber: '99993024',
        orderedBy: 'Daniël Krôgers',
        dueDate: '2023-10-30',
        grossAmount: 524.27,
        vatAmount: 90.99,
        netAmount: 433.28,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Completed,
        openAmount: null,
        reference: '097963-StraatHerstel.06-2024',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '1447564012',
          },
        ],
        deliveryAddress: {
          street: 'Stationsplein 12',
          zipcode: '1012 AB',
          city: 'Amsterdam',
          country: 'NL',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: '6PKGwxgUcvRKVyJSVtxhKJblonBrTksJ',
        invoiceNumber: '4657817365',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-12-18',
        debtorNumber: '99993024',
        orderedBy: 'Daniël Krôgers',
        dueDate: '2023-12-28',
        grossAmount: 524.27,
        vatAmount: 90.99,
        netAmount: 433.28,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Completed,
        openAmount: null,
        reference: '097963-StraatHerstel.06-2024',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '1447564012',
          },
        ],
        deliveryAddress: {
          street: 'Stationsplein 12',
          zipcode: '1012 AB',
          city: 'Amsterdam',
          country: 'NL',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: '6ILQC8EZAC10S4FAAEC2DVAP4VW336RR',
        invoiceNumber: '5620643454',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-07-17',
        debtorNumber: '99993024',
        orderedBy: 'Daniël Krôgers',
        dueDate: '2023-07-27',
        grossAmount: 524.27,
        vatAmount: 90.99,
        netAmount: 433.28,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Overdue,
        openAmount: null,
        reference: '097963-StraatHerstel.06-2024',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '1447564012',
          },
        ],
        deliveryAddress: {
          street: 'Stationsplein 12',
          zipcode: '1012 AB',
          city: 'Amsterdam',
          country: 'NL',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: '7ZJQPMK4IVBYYNQX3NOZIPCOB8UN52RZ',
        invoiceNumber: '4243428848',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-12-04',
        debtorNumber: '99993024',
        orderedBy: 'Daniël Krôgers',
        dueDate: '2023-12-24',
        grossAmount: 524.27,
        vatAmount: 90.99,
        netAmount: 433.28,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Completed,
        openAmount: null,
        reference: '097963-StraatHerstel.06-2024',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '1447564012',
          },
        ],
        deliveryAddress: {
          street: 'Stationsplein 12',
          zipcode: '1012 AB',
          city: 'Amsterdam',
          country: 'NL',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: '6PKGwxgUcvRKVyJSVtxhKJblonBrTksJ',
        invoiceNumber: '3068834660',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-09-24',
        debtorNumber: '99993024',
        orderedBy: 'Daniël Krôgers',
        dueDate: '2023-10-08',
        grossAmount: 524.27,
        vatAmount: 90.99,
        netAmount: 433.28,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Completed,
        openAmount: null,
        reference: '097963-StraatHerstel.06-2024',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '1447564012',
          },
        ],
        deliveryAddress: {
          street: 'Stationsplein 12',
          zipcode: '1012 AB',
          city: 'Amsterdam',
          country: 'NL',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      // end invoices contractnumber 1447564012

      // start invoices contractnumber 3360778345
      {
        id: '74CBDF8CAD224807AA6B20EB5E7C4C3B',
        invoiceNumber: '2238100051',
        invoiceType: 'Credit Note',
        invoiceDate: '2023-03-15',
        debtorNumber: '99993024',
        orderedBy: 'Sasja van Hamersen',
        dueDate: '2023-03-25',
        grossAmount: -197.78,
        vatAmount: -41.53,
        netAmount: -156.25,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Completed,
        openAmount: null,
        reference: '784683-01.2023',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '3360778345',
          },
        ],
        deliveryAddress: {
          street: 'Parkallee 36',
          zipcode: '41974',
          city: 'Ludwigsfelde',
          country: 'DE',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: '9E7D578E1F9A4A0D8E32132EF983BA9E',
        invoiceNumber: '6956681292',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-03-11',
        debtorNumber: '99993024',
        orderedBy: 'Sasja van Hamersen',
        dueDate: '2023-03-22',
        grossAmount: 197.78,
        vatAmount: 41.53,
        netAmount: 156.25,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Completed,
        openAmount: null,
        reference: '784683-01.2023',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '3360778345',
          },
        ],
        deliveryAddress: {
          street: 'Parkallee 36',
          zipcode: '41974',
          city: 'Ludwigsfelde',
          country: 'DE',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: 'F4C011F08EEC43AF8BFCE4A098DCBACE',
        invoiceNumber: '9231908819',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-03-05',
        debtorNumber: '99993024',
        orderedBy: 'Sasja van Hamersen',
        dueDate: '2023-03-12',
        grossAmount: 197.78,
        vatAmount: 41.53,
        netAmount: 156.25,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Overdue,
        openAmount: null,
        reference: '784683-01.2023',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '3360778345',
          },
        ],
        deliveryAddress: {
          street: 'Parkallee 36',
          zipcode: '41974',
          city: 'Ludwigsfelde',
          country: 'DE',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: 'CCA252ED8DC94FB1AE6627EA7D721FA6',
        invoiceNumber: '5809101530',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-02-14',
        debtorNumber: '99993024',
        orderedBy: 'Sasja van Hamersen',
        dueDate: '2023-02-27',
        grossAmount: 197.78,
        vatAmount: 41.53,
        netAmount: 156.25,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Completed,
        openAmount: null,
        reference: '784683-01.2023',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '3360778345',
          },
        ],
        deliveryAddress: {
          street: 'Parkallee 36',
          zipcode: '41974',
          city: 'Ludwigsfelde',
          country: 'DE',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      {
        id: '99B00AC28D174FACAE4B810503416857',
        invoiceNumber: '9174030478',
        invoiceType: 'Hire Invoice',
        invoiceDate: '2023-03-31',
        debtorNumber: '99993024',
        orderedBy: 'Sasja van Hamersen',
        dueDate: '2023-04-09',
        grossAmount: 197.78,
        vatAmount: 41.53,
        netAmount: 156.25,
        currency: 'EUR',
        paymentStatus: PaymentStatusEnum.Overdue,
        openAmount: null,
        reference: '784683-01.2023',
        contracts: [
          {
            type: 'Contract',
            contractNumber: '3360778345',
          },
        ],
        deliveryAddress: {
          street: 'Parkallee 36',
          zipcode: '41974',
          city: 'Ludwigsfelde',
          country: 'DE',
        },
        // makes demo account crash atm
        // attachments: [
        //   {
        //     id: 9921337537,
        //     type: 'invoice',
        //     title: 'invoice9921337537',
        //     mediaType: 'application/pdf',
        //     href: '/invoices/9921337537/documents/9921337537',
        //   },
        // ],
      },
      // end invoices contractnumber 3360778345
    ],
    count: 16,
    offset: '0',
    limit: '20',
  },
  '/contracts/query': {
    // the delivery adress is only taken from the first contract and sin't refreshed for all other contracts
    contracts: [
      // contract 3110116336
      {
        id: '06258D100C0B4AABB7D6085E6CF4EB4C',
        debtorNumber: '99993024',
        contractNumber: '3110116336',
        contractDate: '2023-01-29',
        type: 'Contract',
        status: 'Open',
        startDate: '2023-02-03',
        endDate: '2023-11-20',
        lastInvoiceDate: '2023-11-06',
        reference: '79624-BLS.92.2023-12-08.2023',
        orderedBy: 'Marileen Lammens',
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        executingDepot: {
          code: '311',
          name: 'Echt',
        },
        // this makes the demo account crash at the moment
        // attachments: [
        //   {
        //     id: '239C186633C242EF814B59CE799086BC',
        //     type: 'Contract',
        //     title: 'rentalcontract 9980107831',
        //     mediaType: 'application/pdf',
        //     href: '/contracts/9980107831/documents/239C186633C242EF814B59CE799086BC',
        //     status: 'Unsigned',
        //   },
        // ],
      },
      // contract 1383927878
      {
        id: 'D13F8920BA5649FE89FF5003858708EB',
        debtorNumber: '99993024',
        contractNumber: '1383927878',
        contractDate: '2023-01-16',
        type: 'Contract',
        status: 'ReservationConfirmed',
        startDate: '2024-04-23',
        endDate: null,
        lastInvoiceDate: null,
        reference: '896448-jaarlijkse-werkzaamheden-2024',
        orderedBy: 'Frederik Nielsen',
        deliveryAddress: {
          street: 'Alte Poststraße 11',
          zipcode: '52511',
          city: 'Geilenkirchen',
          country: 'DE',
        },
        executingDepot: {
          code: '138',
          name: 'Erkelenz - Süd',
        },
        // this makes the demo account crash at the moment
        // attachments: [
        //   {
        //     id: '239C186633C242EF814B59CE799086BC',
        //     type: 'Contract',
        //     title: 'rentalcontract 9980107831',
        //     mediaType: 'application/pdf',
        //     href: '/contracts/9980107831/documents/239C186633C242EF814B59CE799086BC',
        //     status: 'Unsigned',
        //   },
        // ],
      },
      // contract 1447564012
      {
        id: '06258D100C0B4AABB7D6085E6CF4EB4C',
        debtorNumber: '99993024',
        contractNumber: '1447564012',
        contractDate: '2023-06-29',
        type: 'Contract',
        status: 'Open',
        startDate: '2023-07-11',
        endDate: null,
        lastInvoiceDate: '2024-01-12',
        reference: '097963-StraatHerstel.06-2024',
        orderedBy: 'Daniël Krôgers',
        deliveryAddress: {
          street: 'Stationsplein 12',
          zipcode: '1012 AB',
          city: 'Amsterdam',
          country: 'NL',
        },
        executingDepot: {
          code: '144',
          name: 'Amsterdam - West',
        },
        // this makes the demo account crash at the moment
        // attachments: [
        //   {
        //     id: '239C186633C242EF814B59CE799086BC',
        //     type: 'Contract',
        //     title: 'rentalcontract 9980107831',
        //     mediaType: 'application/pdf',
        //     href: '/contracts/9980107831/documents/239C186633C242EF814B59CE799086BC',
        //     status: 'Unsigned',
        //   },
        // ],
      },
      // contract 3360778345
      {
        id: '4399341E390A4FE68B8A1085156B43B0',
        debtorNumber: '99993024',
        contractNumber: '3360778345',
        contractDate: '2023-01-01',
        type: 'Contract',
        status: 'Closed',
        startDate: '2023-01-07',
        endDate: '2023-06-30',
        lastInvoiceDate: '2024-01-12',
        reference: '896448-01.2023',
        orderedBy: 'Sasja van Hamersen',
        deliveryAddress: {
          street: 'Parkallee 36',
          zipcode: '41974',
          city: 'Ludwigsfelde',
          country: 'DE',
        },
        executingDepot: {
          code: '336',
          name: 'Münster',
        },
        // this makes the demo account crash at the moment
        // attachments: [
        //   {
        //     id: '239C186633C242EF814B59CE799086BC',
        //     type: 'Contract',
        //     title: 'rentalcontract 9980107831',
        //     mediaType: 'application/pdf',
        //     href: '/contracts/9980107831/documents/239C186633C242EF814B59CE799086BC',
        //     status: 'Unsigned',
        //   },
        // ],
      },
      // contract 3433956901
      {
        id: '23C376A69CED44EB914A0DC2E5D1553C',
        debtorNumber: '99993024',
        contractNumber: '3433956901',
        contractDate: '2024-01-01',
        type: 'Contract',
        status: 'Open',
        startDate: '2024-01-05',
        endDate: null,
        lastInvoiceDate: '2024-01-16',
        reference: '926354-ongoing-hire-01.2024',
        orderedBy: 'Sasja van Hamersen',
        deliveryAddress: {
          street: 'Parkallee 36',
          zipcode: '41974',
          city: 'Ludwigsfelde',
          country: 'DE',
        },
        executingDepot: {
          code: '343',
          name: 'Potsdam',
        },
        // this makes the demo account crash at the moment
        // attachments: [
        //   {
        //     id: '239C186633C242EF814B59CE799086BC',
        //     type: 'Contract',
        //     title: 'rentalcontract 9980107831',
        //     mediaType: 'application/pdf',
        //     href: '/contracts/9980107831/documents/239C186633C242EF814B59CE799086BC',
        //     status: 'Unsigned',
        //   },
        // ],
      },
    ],
    // here the mock API uses count to give total contracts. Is also used for total "open" contracts, this isn't correct
    count: 4,
    offset: '0',
    limit: '20',
  },

  // list with items that are shown in contract items and product-item-list
  '/contractitems/query': {
    contractItems: [
      // 5m³ electric compressor B89EC7E1DAC84373B3E3E8C5D5B299533
      {
        id: 'B89EC7E1DAC84373B3E3E8C5D5B299533',
        contractNumber: '3360778345',
        debtorNumber: '99993024',
        type: 'Contract',
        status: 'Completed',
        reference: '22_38008',
        orderedBy: 'Nico ten Tije',
        deliveryAddress: {
          street: 'Pascalweg 14',
          zipcode: '6101 WV',
          city: 'Echt',
          country: 'NL',
        },
        executingDepot: {
          code: '336',
          name: 'Münster',
        },
        depot: {
          code: '336',
          name: 'Münster',
        },
        startDate: '2023-03-30',
        endDate: null,
        productName: '5m³ electric compressor',
        productNumber: '19002',
        productDescription:
          'The Bio Box is used as a temporary toilet facility. Ideal for construction projects, parties and events.',
        productImages: [
          {
            code: 'F0C737E8-28F1-463F-B1449034DAEE2191',
            name: '18043',
            isPrimary: true,
            formats: [
              {
                code: 'thumbnailF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/4d56558582497c5d/thumbnail-10663.jpg',
              },
              {
                code: 'primaryThumbnailF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'primaryThumbnail',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/thumbnail-18043.jpg',
              },
              {
                code: 'smallF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'small',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/small-18043.jpg',
              },
              {
                code: 'primarySmallF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'primarySmall',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/small-18043.jpg',
              },
              {
                code: 'largeF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'large',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/large-18043.jpg',
              },
              {
                code: 'mediumF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/medium-18043.jpg',
              },
              {
                code: 'onlineF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'online',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/online-18043.png',
              },
            ],
          },
        ],
        isEcoLabel: true,
        isThirdPartyEquipment: false,
        isUniqueItem: false,
        itemNumber: '10663',
        quantity: 1,
        amount: 102.95,
        currency: 'EUR',
        hasAccessories: true,
        accessories: [
          {
            id: '2337C38702854969802E4FCCB80173DF',
            status: 'Completed',
            productName: '* Transport case for 6 pieces radios (15270)',
            itemNumber: '53299',
            productNumber: '53299',
            isThirdPartyEquipment: false,
            quantity: 6,
          },
          {
            id: '01D1EB9CD6DF484DBDE034BC69559889',
            status: 'Completed',
            productName: 'Speaker / microphone Kenwood radio (15270)',
            itemNumber: '53296',
            productNumber: '53296',
            isThirdPartyEquipment: false,
            quantity: 6,
          },
        ],
      },
      // Articulating telescopic boom lift 17m hybrid 4x4 9DB4D1EAAA194B80B6B7F568F16E9F68
      {
        id: '9DB4D1EAAA194B80B6B7F568F16E9F68',
        contractNumber: '3360778345',
        debtorNumber: '99993024',
        type: 'Contract',
        status: 'Waiting for Return',
        reference: '081782-spoedgebruik-01.2024',
        orderedBy: 'Frederik Nielsen',
        deliveryAddress: {
          street: 'Parkallee 36',
          zipcode: '41974',
          city: 'Ludwigsfelde',
          country: 'DE',
        },
        executingDepot: {
          code: '336',
          name: 'Münster',
        },
        depot: {
          code: '336',
          name: 'Münster',
        },
        startDate: '2023-04-04',
        endDate: null,
        productName: 'Articulating telescopic boom lift 17m hybrid 4x4',
        productNumber: '19002',
        productDescription:
          'Self-propelled aerial work platform with a highly variable range and high manoeuvrability. Particularly suitable for work in warehouses, factories or above production lines, for example.',
        productImages: [
          {
            code: 'F0C737E8-28F1-463F-B1449034DAEE2191',
            name: '18043',
            isPrimary: true,
            formats: [
              {
                code: 'thumbnailF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/12845b3f0bb12d58/thumbnail-18028.jpg',
              },
              {
                code: 'primaryThumbnailF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'primaryThumbnail',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/thumbnail-18043.jpg',
              },
              {
                code: 'smallF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'small',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/small-18043.jpg',
              },
              {
                code: 'primarySmallF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'primarySmall',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/small-18043.jpg',
              },
              {
                code: 'largeF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'large',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/large-18043.jpg',
              },
              {
                code: 'mediumF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/medium-18043.jpg',
              },
              {
                code: 'onlineF0C737E8-28F1-463F-B1449034DAEE2191',
                type: 'online',
                url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/online-18043.png',
              },
            ],
          },
        ],
        isEcoLabel: true,
        isThirdPartyEquipment: false,
        isUniqueItem: true,
        itemNumber: '12070',
        quantity: 1.0,
        amount: 76.94,
        currency: 'EUR',
        hasAccessories: true,
        equipmentMetrics: {
          itemNumber: '120630171',
          equipmentId: 'JCB5AB7GVK2790273',
          pin: 'JCB5AB7GVK2790273',
          owner: 'Boels',
          market: 'NL',
          oemName: 'JCB',
          model: '535-95',
          telematicsSerialNumber: 'AEMP-JCB-JCB5AB7GVK2790273',
          telematicsMachineId: '00000000-0000-0000-0000-000000723476',
          telematicsUnitId: '2898251',
          externalReferenceNumber: 'JCB5AB7GVK2790273',
          location: {
            latitude: 50.9614999,
            longitude: 6.1240115,
            altitude: -16,
            altitudeUnits: 'metre',
            dateTime: '2023-04-27T00:21:41Z',
          },
          cumulativeIdleHours: {
            dateTime: '2023-04-27T00:21:42Z',
            amountOfHours: 24.9,
          },
          cumulativeOperatingHours: {
            dateTime: '2023-04-27T00:21:42Z',
            amountOfHours: 28.3,
          },
          fuelUsed: {
            fuelUnits: 'litre',
            fuelConsumed: 50,
            dateTime: '2023-04-27T00:21:42Z',
          },
          fuelUsedLast24: {
            fuelUnits: 'litre',
            fuelConsumed: 32,
            dateTime: '2023-04-26T23:00:00Z',
          },
          fuelRemaining: {
            percent: 70.8,
            dateTime: '2023-04-27T00:21:42Z',
          },
          engineStatus: {
            running: false,
            dateTime: '2023-04-26T12:05:34Z',
          },
          maximumSpeedLast24: {
            speedUnits: 'kilometres per hour',
            speed: 4.23,
            dateTime: '2023-04-26T23:00:00Z',
          },
          batteryPotential: {
            dateTime: '2023-04-27T00:21:42Z',
            value: 121.6,
            valueUnits: 'volt',
          },
          dailyMachineHours: [
            {
              dateTime: '2023-09-26',
              amountOfHours: 0,
            },
            {
              dateTime: '2023-09-25',
              amountOfHours: 0,
            },
            {
              dateTime: '2023-09-24',
              amountOfHours: 0,
            },
            {
              dateTime: '2023-09-23',
              amountOfHours: 6,
            },
            {
              dateTime: '2023-09-22',
              amountOfHours: 4,
            },
            {
              dateTime: '2023-09-21',
              amountOfHours: 2,
            },
            {
              dateTime: '2023-09-20',
              amountOfHours: 0,
            },
            {
              dateTime: '2023-09-19',
              amountOfHours: 0,
            },
            {
              dateTime: '2023-09-18',
              amountOfHours: 0,
            },
            {
              dateTime: '2023-09-17',
              amountOfHours: 6,
            },
            {
              dateTime: '2023-09-16',
              amountOfHours: 4,
            },
            {
              dateTime: '2023-09-15',
              amountOfHours: 4,
            },
            {
              dateTime: '2023-09-14',
              amountOfHours: 6,
            },
            {
              dateTime: '2023-09-13',
              amountOfHours: 5,
            },
          ],
          faults: [
            {
              dateTime: '2023-04-17T11:41:20Z',
              code: '16667204',
              description: 'Lift / Lower Spool 1 OORL (C152F-23)',
              severity: 'Minor',
            },
            {
              dateTime: '2023-04-17T11:41:20Z',
              code: '16667236',
              description: 'Ext / Retract Spool 1 OORL (C1534-62)',
              severity: 'Minor',
            },
            {
              dateTime: '2023-04-17T11:41:20Z',
              code: '16673700',
              description: 'Steer B Solenoid STG (C1570-11)',
              severity: 'Minor',
            },
          ],
        },
      },
      //biobox 1 B89EC7E1DAC84373B3E3E8C5D5B29953
      {
        id: 'B89EC7E1DAC84373B3E3E8C5D5B29953',
        contractNumber: '3110116336',
        debtorNumber: '99993024',
        type: 'Contract',
        status: 'Open',
        reference: '23/34015',
        orderedBy: 'Marije Engel',
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        executingDepot: {
          code: '311',
          name: 'Echt',
        },
        depot: {
          code: '311',
          name: 'Echt',
        },
        startDate: '2023-12-04',
        endDate: null,
        productName: 'Bio box complete',
        productNumber: '19002',
        productDescription:
          'The Bio Box is used as a temporary toilet facility. Ideal for construction projects, parties and events.',
        productImages: [
          {
            code: 'A373A2C7-BC13-4E1A-A7B0546F89832E8C',
            name: '19002',
            isPrimary: true,
            formats: [
              {
                code: 'thumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
              },
              {
                code: 'smallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'small',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
              },
              {
                code: 'primarySmallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'primarySmall',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
              },
              {
                code: 'mediumA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/medium-19002.jpg',
              },
              {
                code: 'largeA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'large',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/large-19002.jpg',
              },
              {
                code: 'onlineA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'online',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/online-19002.png',
              },
              {
                code: 'primaryThumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'primaryThumbnail',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
              },
            ],
          },
          {
            code: '878E3F40-0781-418B-89C9D447CCBF9291',
            name: 'Bio Box mobiel toilet',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnail878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/thumbnail-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'small878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'small',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/small-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'medium878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/medium-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'large878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'large',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/large-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'online878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'online',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/online-Bio-Box-mobiel-toilet.png',
              },
            ],
          },
          {
            code: 'D991CD8F-5743-40CF-88384E60B6ACA79B',
            name: 'Bio Box mobiel toilet',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnailD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/thumbnail-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'smallD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'small',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/small-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'mediumD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/medium-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'largeD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'large',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/large-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'onlineD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'online',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/online-Bio-Box-mobiel-toilet.png',
              },
            ],
          },
        ],
        isEcoLabel: false,
        isThirdPartyEquipment: false,
        isUniqueItem: false,
        itemNumber: '19002',
        quantity: 1,
        amount: 23.38,
        currency: 'EUR',
        hasAccessories: false,
      },
      //biobox 2 BA55984703184036BA6544AE04EB1FAB
      {
        id: 'BA55984703184036BA6544AE04EB1FAB',
        contractNumber: '3110116336',
        debtorNumber: '99993024',
        type: 'Contract',
        status: 'Open',
        reference: '23/34015',
        orderedBy: 'Marije Engel',
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        executingDepot: {
          code: '311',
          name: 'Echt',
        },
        depot: {
          code: '311',
          name: 'Echt',
        },
        startDate: '2023-12-04',
        endDate: null,
        productName: 'Bio box complete',
        productNumber: '19002',
        productDescription:
          'The Bio Box is used as a temporary toilet facility. Ideal for construction projects, parties and events.',
        productImages: [
          {
            code: 'A373A2C7-BC13-4E1A-A7B0546F89832E8C',
            name: '19002',
            isPrimary: true,
            formats: [
              {
                code: 'thumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
              },
              {
                code: 'smallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'small',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
              },
              {
                code: 'primarySmallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'primarySmall',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
              },
              {
                code: 'mediumA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/medium-19002.jpg',
              },
              {
                code: 'largeA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'large',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/large-19002.jpg',
              },
              {
                code: 'onlineA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'online',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/online-19002.png',
              },
              {
                code: 'primaryThumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'primaryThumbnail',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
              },
            ],
          },
          {
            code: '878E3F40-0781-418B-89C9D447CCBF9291',
            name: 'Bio Box mobiel toilet',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnail878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/thumbnail-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'small878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'small',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/small-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'medium878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/medium-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'large878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'large',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/large-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'online878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'online',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/online-Bio-Box-mobiel-toilet.png',
              },
            ],
          },
          {
            code: 'D991CD8F-5743-40CF-88384E60B6ACA79B',
            name: 'Bio Box mobiel toilet',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnailD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/thumbnail-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'smallD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'small',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/small-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'mediumD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/medium-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'largeD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'large',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/large-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'onlineD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'online',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/online-Bio-Box-mobiel-toilet.png',
              },
            ],
          },
        ],
        isEcoLabel: false,
        isThirdPartyEquipment: false,
        isUniqueItem: false,
        itemNumber: '19002',
        quantity: 1,
        amount: 23.38,
        currency: 'EUR',
        hasAccessories: false,
      },
      //biobox 3 2C874C82C8AC4519A95F865EF4E4BAFB
      {
        id: '2C874C82C8AC4519A95F865EF4E4BAFB',
        contractNumber: '3110116336',
        debtorNumber: '99993024',
        type: 'Contract',
        status: 'Open',
        reference: '23/34015',
        orderedBy: 'Marije Engel',
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        executingDepot: {
          code: '311',
          name: 'Echt',
        },
        depot: {
          code: '311',
          name: 'Echt',
        },
        startDate: '2023-12-04',
        endDate: null,
        productName: 'Bio box complete',
        productNumber: '19002',
        productDescription:
          'The Bio Box is used as a temporary toilet facility. Ideal for construction projects, parties and events.',
        productImages: [
          {
            code: 'A373A2C7-BC13-4E1A-A7B0546F89832E8C',
            name: '19002',
            isPrimary: true,
            formats: [
              {
                code: 'thumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
              },
              {
                code: 'smallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'small',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
              },
              {
                code: 'primarySmallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'primarySmall',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
              },
              {
                code: 'mediumA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/medium-19002.jpg',
              },
              {
                code: 'largeA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'large',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/large-19002.jpg',
              },
              {
                code: 'onlineA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'online',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/online-19002.png',
              },
              {
                code: 'primaryThumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'primaryThumbnail',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
              },
            ],
          },
          {
            code: '878E3F40-0781-418B-89C9D447CCBF9291',
            name: 'Bio Box mobiel toilet',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnail878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/thumbnail-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'small878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'small',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/small-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'medium878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/medium-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'large878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'large',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/large-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'online878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'online',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/online-Bio-Box-mobiel-toilet.png',
              },
            ],
          },
          {
            code: 'D991CD8F-5743-40CF-88384E60B6ACA79B',
            name: 'Bio Box mobiel toilet',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnailD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/thumbnail-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'smallD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'small',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/small-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'mediumD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/medium-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'largeD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'large',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/large-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'onlineD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'online',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/online-Bio-Box-mobiel-toilet.png',
              },
            ],
          },
        ],
        isEcoLabel: false,
        isThirdPartyEquipment: false,
        isUniqueItem: false,
        itemNumber: '19002',
        quantity: 1,
        amount: 23.38,
        currency: 'EUR',
        hasAccessories: false,
      },
      //biobox 4 86E445F509424495826435A076049062
      {
        id: '86E445F509424495826435A076049062',
        contractNumber: '3110116336',
        debtorNumber: '99993024',
        type: 'Contract',
        status: 'Open',
        reference: '23/34015',
        orderedBy: 'Marije Engel',
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        executingDepot: {
          code: '311',
          name: 'Echt',
        },
        depot: {
          code: '311',
          name: 'Echt',
        },
        startDate: '2023-12-04',
        endDate: null,
        productName: 'Bio box complete',
        productNumber: '19002',
        productDescription:
          'The Bio Box is used as a temporary toilet facility. Ideal for construction projects, parties and events.',
        productImages: [
          {
            code: 'A373A2C7-BC13-4E1A-A7B0546F89832E8C',
            name: '19002',
            isPrimary: true,
            formats: [
              {
                code: 'thumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
              },
              {
                code: 'smallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'small',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
              },
              {
                code: 'primarySmallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'primarySmall',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
              },
              {
                code: 'mediumA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/medium-19002.jpg',
              },
              {
                code: 'largeA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'large',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/large-19002.jpg',
              },
              {
                code: 'onlineA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'online',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/online-19002.png',
              },
              {
                code: 'primaryThumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'primaryThumbnail',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
              },
            ],
          },
          {
            code: '878E3F40-0781-418B-89C9D447CCBF9291',
            name: 'Bio Box mobiel toilet',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnail878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/thumbnail-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'small878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'small',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/small-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'medium878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/medium-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'large878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'large',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/large-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'online878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'online',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/online-Bio-Box-mobiel-toilet.png',
              },
            ],
          },
          {
            code: 'D991CD8F-5743-40CF-88384E60B6ACA79B',
            name: 'Bio Box mobiel toilet',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnailD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/thumbnail-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'smallD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'small',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/small-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'mediumD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/medium-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'largeD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'large',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/large-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'onlineD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'online',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/online-Bio-Box-mobiel-toilet.png',
              },
            ],
          },
        ],
        isEcoLabel: false,
        isThirdPartyEquipment: false,
        isUniqueItem: false,
        itemNumber: '19002',
        quantity: 1,
        amount: 23.38,
        currency: 'EUR',
        hasAccessories: false,
      },
      //biobox 5 2963F98E9ED644D6AFBBD844BE0CE0EB
      {
        id: '2963F98E9ED644D6AFBBD844BE0CE0EB',
        contractNumber: '3110116336',
        debtorNumber: '99993024',
        type: 'Contract',
        status: 'Open',
        reference: '23/34015',
        orderedBy: 'Marije Engel',
        deliveryAddress: {
          street: 'Walstraat 11',
          zipcode: '6101 CG',
          city: 'Echt',
          country: 'NL',
        },
        executingDepot: {
          code: '311',
          name: 'Echt',
        },
        depot: {
          code: '311',
          name: 'Echt',
        },
        startDate: '2023-12-04',
        endDate: null,
        productName: 'Bio box complete',
        productNumber: '19002',
        productDescription:
          'The Bio Box is used as a temporary toilet facility. Ideal for construction projects, parties and events.',
        productImages: [
          {
            code: 'A373A2C7-BC13-4E1A-A7B0546F89832E8C',
            name: '19002',
            isPrimary: true,
            formats: [
              {
                code: 'thumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
              },
              {
                code: 'smallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'small',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
              },
              {
                code: 'primarySmallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'primarySmall',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
              },
              {
                code: 'mediumA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/medium-19002.jpg',
              },
              {
                code: 'largeA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'large',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/large-19002.jpg',
              },
              {
                code: 'onlineA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'online',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/online-19002.png',
              },
              {
                code: 'primaryThumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
                type: 'primaryThumbnail',
                url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
              },
            ],
          },
          {
            code: '878E3F40-0781-418B-89C9D447CCBF9291',
            name: 'Bio Box mobiel toilet',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnail878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/thumbnail-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'small878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'small',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/small-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'medium878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/medium-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'large878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'large',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/large-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'online878E3F40-0781-418B-89C9D447CCBF9291',
                type: 'online',
                url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/online-Bio-Box-mobiel-toilet.png',
              },
            ],
          },
          {
            code: 'D991CD8F-5743-40CF-88384E60B6ACA79B',
            name: 'Bio Box mobiel toilet',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnailD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/thumbnail-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'smallD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'small',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/small-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'mediumD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/medium-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'largeD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'large',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/large-Bio-Box-mobiel-toilet.jpg',
              },
              {
                code: 'onlineD991CD8F-5743-40CF-88384E60B6ACA79B',
                type: 'online',
                url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/online-Bio-Box-mobiel-toilet.png',
              },
            ],
          },
        ],
        isEcoLabel: false,
        isThirdPartyEquipment: false,
        isUniqueItem: false,
        itemNumber: '19002',
        quantity: 1,
        amount: 23.38,
        currency: 'EUR',
        hasAccessories: false,
      },
      // Electrical slide gate 46A2D69CA26945EB8793C9018E73EEA3
      {
        id: '46A2D69CA26945EB8793C9018E73EEA3',
        contractNumber: '3360778345',
        debtorNumber: '99993024',
        type: 'Contract',
        status: 'Completed',
        reference: '896448-01.2023',
        orderedBy: 'Sasja van Hamersen',
        deliveryAddress: {
          street: 'Parkallee 36',
          zipcode: '41974',
          city: 'Ludwigsfelde',
          country: 'DE',
        },
        executingDepot: {
          code: '336',
          name: 'Münster',
        },
        depot: {
          code: '336',
          name: 'Münster',
        },
        startDate: '2023-01-07',
        endDate: '2023-06-30',
        productName: 'Electrical slide gate, 7 metres',
        productNumber: '13238',
        productDescription:
          "Basic system that you can use for all your painting, maintenance, renovation and installation jobs. Quick and easy to set up with a modular design that allows you to extend the ladder in height and width. You're well protected with the Safe-Quick Guardrail mobile scaffold, even before you access the platform. Unlike the wide freestanding scaffold, the wall remains completely untouched in this configuration. Suitable for both indoor and outdoor use.Maximum free-standing height of 8.2 m (work platform height) for outdoor use and 12.2 m for indoor use. If the mobile scaffold needs to be set up higher than these working heights, it must meet the same requirements as a fixed scaffold and therefore should be anchored in position.",
        productImages: [
          {
            code: '66298209-98BC-41E6-92CA5B37664B9124',
            name: 'Schuifpoort 230 V- 7 meter',
            isPrimary: true,
            formats: [
              {
                code: 'thumbnail66298209-98BC-41E6-92CA5B37664B9124',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/8263b4a181669269/thumbnail-Schuifpoort-230-V-7-meter.jpg',
              },
              {
                code: 'small66298209-98BC-41E6-92CA5B37664B9124',
                type: 'small',
                url: 'https://mediahub.boels.com/m/8263b4a181669269/small-Schuifpoort-230-V-7-meter.jpg',
              },
              {
                code: 'medium66298209-98BC-41E6-92CA5B37664B9124',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/8263b4a181669269/medium-Schuifpoort-230-V-7-meter.jpg',
              },
              {
                code: 'large66298209-98BC-41E6-92CA5B37664B9124',
                type: 'large',
                url: 'https://mediahub.boels.com/m/8263b4a181669269/large-Schuifpoort-230-V-7-meter.jpg',
              },
              {
                code: 'online66298209-98BC-41E6-92CA5B37664B9124',
                type: 'online',
                url: 'https://mediahub.boels.com/m/8263b4a181669269/online-Schuifpoort-230-V-7-meter.png',
              },
              {
                code: 'primaryThumbnail66298209-98BC-41E6-92CA5B37664B9124',
                type: 'primaryThumbnail',
                url: 'https://mediahub.boels.com/m/8263b4a181669269/thumbnail-Schuifpoort-230-V-7-meter.jpg',
              },
              {
                code: 'primarySmall66298209-98BC-41E6-92CA5B37664B9124',
                type: 'primarySmall',
                url: 'https://mediahub.boels.com/m/8263b4a181669269/small-Schuifpoort-230-V-7-meter.jpg',
              },
            ],
          },
          {
            code: '0A59507B-138D-44D9-B9D4275C18FC47D8',
            name: 'Schuifpoort 230 V- 7 meter',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnail0A59507B-138D-44D9-B9D4275C18FC47D8',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/47d9854dcb9f17c8/thumbnail-Schuifpoort-230-V-7-meter.jpg',
              },
              {
                code: 'small0A59507B-138D-44D9-B9D4275C18FC47D8',
                type: 'small',
                url: 'https://mediahub.boels.com/m/47d9854dcb9f17c8/small-Schuifpoort-230-V-7-meter.jpg',
              },
              {
                code: 'medium0A59507B-138D-44D9-B9D4275C18FC47D8',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/47d9854dcb9f17c8/medium-Schuifpoort-230-V-7-meter.jpg',
              },
              {
                code: 'large0A59507B-138D-44D9-B9D4275C18FC47D8',
                type: 'large',
                url: 'https://mediahub.boels.com/m/47d9854dcb9f17c8/large-Schuifpoort-230-V-7-meter.jpg',
              },
              {
                code: 'online0A59507B-138D-44D9-B9D4275C18FC47D8',
                type: 'online',
                url: 'https://mediahub.boels.com/m/47d9854dcb9f17c8/online-Schuifpoort-230-V-7-meter.png',
              },
            ],
          },
        ],
        isEcoLabel: false,
        isThirdPartyEquipment: false,
        isUniqueItem: true,
        itemNumber: '13238',
        quantity: 1.0,
        quantityReturned: 0.0,
        amount: 1170.0,
        currency: 'EUR',
        hasAccessories: true,
      },
      // Acrow prop 1.80 - 3.00 m B5B6BF025E5944C3832B12062E376578
      {
        id: 'B5B6BF025E5944C3832B12062E376578',
        contractNumber: '3360778345',
        debtorNumber: '99993024',
        type: 'Contract',
        status: 'Completed',
        reference: '896448-01.2023',
        orderedBy: 'Sasja van Hamersen',
        deliveryAddress: {
          street: 'Parkallee 36',
          zipcode: '41974',
          city: 'Ludwigsfelde',
          country: 'DE',
        },
        executingDepot: {
          code: '336',
          name: 'Münster',
        },
        depot: {
          code: '336',
          name: 'Münster',
        },
        startDate: '2023-01-07',
        endDate: '2023-06-30',
        productName: 'Acrow prop 1.80 - 3.00 m',
        productNumber: '14777',
        productImages: [
          {
            code: '920265BA-84D7-479A-86E864CC7AE381F2',
            name: 'PROG-605 - 14777',
            isPrimary: true,
            formats: [
              {
                code: 'thumbnail920265BA-84D7-479A-86E864CC7AE381F2',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/thumbnail-PROG-605-14777.jpg',
              },
              {
                code: 'primarySmall920265BA-84D7-479A-86E864CC7AE381F2',
                type: 'primarySmall',
                url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/small-PROG-605-14777.jpg',
              },
              {
                code: 'large920265BA-84D7-479A-86E864CC7AE381F2',
                type: 'large',
                url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/large-PROG-605-14777.jpg',
              },
              {
                code: 'small920265BA-84D7-479A-86E864CC7AE381F2',
                type: 'small',
                url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/small-PROG-605-14777.jpg',
              },
              {
                code: 'online920265BA-84D7-479A-86E864CC7AE381F2',
                type: 'online',
                url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/online-PROG-605-14777.png',
              },
              {
                code: 'medium920265BA-84D7-479A-86E864CC7AE381F2',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/medium-PROG-605-14777.jpg',
              },
              {
                code: 'primaryThumbnail920265BA-84D7-479A-86E864CC7AE381F2',
                type: 'primaryThumbnail',
                url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/thumbnail-PROG-605-14777.jpg',
              },
            ],
          },
          {
            code: '36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
            name: 'Schroefstempel 150 - 250 cm',
            isPrimary: false,
            formats: [
              {
                code: 'thumbnail36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
                type: 'thumbnail',
                url: 'https://mediahub.boels.com/m/eb53a421a1484478/thumbnail-Schroefstempel-150-250-cm.jpg',
              },
              {
                code: 'small36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
                type: 'small',
                url: 'https://mediahub.boels.com/m/eb53a421a1484478/small-Schroefstempel-150-250-cm.jpg',
              },
              {
                code: 'medium36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
                type: 'medium',
                url: 'https://mediahub.boels.com/m/eb53a421a1484478/medium-Schroefstempel-150-250-cm.jpg',
              },
              {
                code: 'large36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
                type: 'large',
                url: 'https://mediahub.boels.com/m/eb53a421a1484478/large-Schroefstempel-150-250-cm.jpg',
              },
              {
                code: 'online36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
                type: 'online',
                url: 'https://mediahub.boels.com/m/eb53a421a1484478/online-Schroefstempel-150-250-cm.png',
              },
            ],
          },
        ],
        isEcoLabel: false,
        isThirdPartyEquipment: false,
        isUniqueItem: false,
        itemNumber: '14777',
        quantity: 178,
        quantityReturned: 0.0,
        amount: 24.2,
        currency: 'EUR',
      },
    ],
    count: 8,
    offset: '0',
    limit: '20',
  },
  // 5m³ electric compressor B89EC7E1DAC84373B3E3E8C5D5B299533
  '/customers/99993024/contractitems/B89EC7E1DAC84373B3E3E8C5D5B299533': {
    id: '9DB4D1EAAA194B80B6B7F568F16E9F68',
    contractNumber: '3360778345',
    debtorNumber: '99993024',
    type: 'Contract',
    status: 'Completed',
    reference: '896448-01.2023',
    orderedBy: 'Sasja van Hamersen',
    deliveryAddress: {
      street: 'Parkallee 36',
      zipcode: '41974',
      city: 'Ludwigsfelde',
      country: 'DE',
    },
    executingDepot: {
      code: '336',
      name: 'Münster',
    },
    depot: {
      code: '336',
      name: 'Münster',
    },
    startDate: '2023-01-07',
    endDate: '2023-06-30',
    productName: '5m³ electric compressor',
    productNumber: '19002',
    productDescription:
      'The Bio Box is used as a temporary toilet facility. Ideal for construction projects, parties and events.',
    productImages: [
      {
        code: 'F0C737E8-28F1-463F-B1449034DAEE2191',
        name: '18043',
        isPrimary: true,
        formats: [
          {
            code: 'thumbnailF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/4d56558582497c5d/thumbnail-10663.jpg',
          },
          {
            code: 'primaryThumbnailF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'primaryThumbnail',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/thumbnail-18043.jpg',
          },
          {
            code: 'smallF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'small',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/small-18043.jpg',
          },
          {
            code: 'primarySmallF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'primarySmall',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/small-18043.jpg',
          },
          {
            code: 'largeF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'large',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/large-18043.jpg',
          },
          {
            code: 'mediumF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/medium-18043.jpg',
          },
          {
            code: 'onlineF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'online',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/online-18043.png',
          },
        ],
      },
    ],
    isEcoLabel: true,
    isThirdPartyEquipment: false,
    isUniqueItem: false,
    itemNumber: '10663',
    quantity: 1,
    amount: 102.95,
    currency: 'EUR',
    hasAccessories: true,
    accessories: [
      {
        id: '2337C38702854969802E4FCCB80173DF',
        status: 'Completed',
        productName: '* Transport case for 6 pieces radios (15270)',
        itemNumber: '53299',
        productNumber: '53299',
        isThirdPartyEquipment: false,
        quantity: 6,
      },
      {
        id: '01D1EB9CD6DF484DBDE034BC69559889',
        status: 'Completed',
        productName: 'Speaker / microphone Kenwood radio (15270)',
        itemNumber: '53296',
        productNumber: '53296',
        isThirdPartyEquipment: false,
        quantity: 6,
      },
    ],
  },
  // Articulating telescopic boom lift 17m hybrid 4x4 9DB4D1EAAA194B80B6B7F568F16E9F68
  '/customers/99993024/contractitems/9DB4D1EAAA194B80B6B7F568F16E9F68': {
    id: '9DB4D1EAAA194B80B6B7F568F16E9F68',
    contractNumber: '3360778345',
    debtorNumber: '99993024',
    type: 'Contract',
    status: 'Waiting for Return',
    reference: '896448-01.2023',
    orderedBy: 'Sasja van Hamersen',
    deliveryAddress: {
      street: 'Parkallee 36',
      zipcode: '41974',
      city: 'Ludwigsfelde',
      country: 'DE',
    },
    executingDepot: {
      code: '336',
      name: 'Münster',
    },
    depot: {
      code: '336',
      name: 'Münster',
    },
    startDate: '2023-01-07',
    endDate: '2023-06-30',
    productName: 'Articulating telescopic boom lift 17m hybrid 4x4',
    productNumber: '19002',
    productDescription:
      'Self-propelled aerial work platform with a highly variable range and high manoeuvrability. Particularly suitable for work in warehouses, factories or above production lines, for example.',
    productImages: [
      {
        code: 'F0C737E8-28F1-463F-B1449034DAEE2191',
        name: '18043',
        isPrimary: true,
        formats: [
          {
            code: 'thumbnailF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/12845b3f0bb12d58/thumbnail-18028.jpg',
          },
          {
            code: 'primaryThumbnailF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'primaryThumbnail',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/thumbnail-18043.jpg',
          },
          {
            code: 'smallF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'small',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/small-18043.jpg',
          },
          {
            code: 'primarySmallF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'primarySmall',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/small-18043.jpg',
          },
          {
            code: 'largeF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'large',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/large-18043.jpg',
          },
          {
            code: 'mediumF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/medium-18043.jpg',
          },
          {
            code: 'onlineF0C737E8-28F1-463F-B1449034DAEE2191',
            type: 'online',
            url: 'https://mediahub.boels.com/m/2992fe51d20c8fc/online-18043.png',
          },
        ],
      },
    ],
    isEcoLabel: true,
    isThirdPartyEquipment: false,
    isUniqueItem: true,
    itemNumber: '12070',
    quantity: 1.0,
    amount: 76.94,
    currency: 'EUR',
    hasAccessories: true,
    equipmentMetrics: {
      itemNumber: '120630171',
      equipmentId: 'JCB5AB7GVK2790273',
      pin: 'JCB5AB7GVK2790273',
      owner: 'Boels',
      market: 'NL',
      oemName: 'JCB',
      model: '535-95',
      telematicsSerialNumber: 'AEMP-JCB-JCB5AB7GVK2790273',
      telematicsMachineId: '00000000-0000-0000-0000-000000723476',
      telematicsUnitId: '2898251',
      externalReferenceNumber: 'JCB5AB7GVK2790273',
      location: {
        latitude: 50.9614999,
        longitude: 6.1240115,
        altitude: -16,
        altitudeUnits: 'metre',
        dateTime: '2023-04-27T00:21:41Z',
      },
      cumulativeIdleHours: {
        dateTime: '2023-04-27T00:21:42Z',
        amountOfHours: 24.9,
      },
      cumulativeOperatingHours: {
        dateTime: '2023-04-27T00:21:42Z',
        amountOfHours: 28.3,
      },
      fuelUsed: {
        fuelUnits: 'litre',
        fuelConsumed: 50,
        dateTime: '2023-04-27T00:21:42Z',
      },
      fuelUsedLast24: {
        fuelUnits: 'litre',
        fuelConsumed: 32,
        dateTime: '2023-04-26T23:00:00Z',
      },
      fuelRemaining: {
        percent: 70.8,
        dateTime: '2023-04-27T00:21:42Z',
      },
      engineStatus: {
        running: false,
        dateTime: '2023-04-26T12:05:34Z',
      },
      maximumSpeedLast24: {
        speedUnits: 'kilometres per hour',
        speed: 4.23,
        dateTime: '2023-04-26T23:00:00Z',
      },
      batteryPotential: {
        dateTime: '2023-04-27T00:21:42Z',
        value: 121.6,
        valueUnits: 'volt',
      },
      dailyMachineHours: [
        {
          dateTime: '2023-09-26',
          amountOfHours: 0,
        },
        {
          dateTime: '2023-09-25',
          amountOfHours: 0,
        },
        {
          dateTime: '2023-09-24',
          amountOfHours: 0,
        },
        {
          dateTime: '2023-09-23',
          amountOfHours: 6,
        },
        {
          dateTime: '2023-09-22',
          amountOfHours: 4,
        },
        {
          dateTime: '2023-09-21',
          amountOfHours: 2,
        },
        {
          dateTime: '2023-09-20',
          amountOfHours: 0,
        },
        {
          dateTime: '2023-09-19',
          amountOfHours: 0,
        },
        {
          dateTime: '2023-09-18',
          amountOfHours: 0,
        },
        {
          dateTime: '2023-09-17',
          amountOfHours: 6,
        },
        {
          dateTime: '2023-09-16',
          amountOfHours: 4,
        },
        {
          dateTime: '2023-09-15',
          amountOfHours: 4,
        },
        {
          dateTime: '2023-09-14',
          amountOfHours: 6,
        },
        {
          dateTime: '2023-09-13',
          amountOfHours: 5,
        },
      ],
      faults: [
        {
          dateTime: '2023-04-17T11:41:20Z',
          code: '16667204',
          description: 'Lift / Lower Spool 1 OORL (C152F-23)',
          severity: 'Minor',
        },
        {
          dateTime: '2023-04-17T11:41:20Z',
          code: '16667236',
          description: 'Ext / Retract Spool 1 OORL (C1534-62)',
          severity: 'Minor',
        },
        {
          dateTime: '2023-04-17T11:41:20Z',
          code: '16673700',
          description: 'Steer B Solenoid STG (C1570-11)',
          severity: 'Minor',
        },
      ],
    },
  },
  //biobox 1 B89EC7E1DAC84373B3E3E8C5D5B29953
  '/customers/99993024/contractitems/B89EC7E1DAC84373B3E3E8C5D5B29953': {
    id: 'B89EC7E1DAC84373B3E3E8C5D5B29953',
    debtorNumber: '99993024',
    contractNumber: '3110116336',
    type: 'Contract',
    status: 'Open',
    reference: '23/34015',
    orderedBy: 'Marije Engel',
    deliveryAddress: {
      street: 'Pascalweg 14',
      zipcode: '6101 WV',
      city: 'Echt',
      country: 'NL',
    },
    executingDepot: {
      code: '311',
      name: 'Echt',
    },
    depot: {
      code: '311',
      name: 'Echt',
    },
    startDate: '2023-12-04',
    productName: 'Bio box complete',
    productNumber: '19002',
    productImages: [
      {
        code: 'A373A2C7-BC13-4E1A-A7B0546F89832E8C',
        name: '19002',
        isPrimary: true,
        formats: [
          {
            code: 'thumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
          },
          {
            code: 'smallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'small',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
          },
          {
            code: 'primarySmallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'primarySmall',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
          },
          {
            code: 'mediumA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/medium-19002.jpg',
          },
          {
            code: 'largeA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'large',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/large-19002.jpg',
          },
          {
            code: 'onlineA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'online',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/online-19002.png',
          },
          {
            code: 'primaryThumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'primaryThumbnail',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
          },
        ],
      },
    ],
    isEcoLabel: false,
    isThirdPartyEquipment: false,
    isUniqueItem: false,
    itemNumber: '19002',
    quantity: 1,
    amount: 23.38,
    currency: 'EUR',
  },
  //biobox 2 BA55984703184036BA6544AE04EB1FAB
  '/customers/99993024/contractitems/BA55984703184036BA6544AE04EB1FAB': {
    id: 'BA55984703184036BA6544AE04EB1FAB',
    contractNumber: '3110116336',
    debtorNumber: '99993024',
    type: 'Contract',
    status: 'Open',
    reference: '23/34015',
    orderedBy: 'Marije Engel',
    deliveryAddress: {
      street: 'Walstraat 11',
      zipcode: '6101 CG',
      city: 'Echt',
      country: 'NL',
    },
    executingDepot: {
      code: '311',
      name: 'Echt',
    },
    depot: {
      code: '311',
      name: 'Echt',
    },
    startDate: '2023-12-04',
    endDate: null,
    productName: 'Bio box complete',
    productNumber: '19002',
    productDescription:
      'The Bio Box is used as a temporary toilet facility. Ideal for construction projects, parties and events.',
    productImages: [
      {
        code: 'A373A2C7-BC13-4E1A-A7B0546F89832E8C',
        name: '19002',
        isPrimary: true,
        formats: [
          {
            code: 'thumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
          },
          {
            code: 'smallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'small',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
          },
          {
            code: 'primarySmallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'primarySmall',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
          },
          {
            code: 'mediumA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/medium-19002.jpg',
          },
          {
            code: 'largeA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'large',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/large-19002.jpg',
          },
          {
            code: 'onlineA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'online',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/online-19002.png',
          },
          {
            code: 'primaryThumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'primaryThumbnail',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
          },
        ],
      },
      {
        code: '878E3F40-0781-418B-89C9D447CCBF9291',
        name: 'Bio Box mobiel toilet',
        isPrimary: false,
        formats: [
          {
            code: 'thumbnail878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/thumbnail-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'small878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'small',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/small-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'medium878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/medium-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'large878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'large',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/large-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'online878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'online',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/online-Bio-Box-mobiel-toilet.png',
          },
        ],
      },
      {
        code: 'D991CD8F-5743-40CF-88384E60B6ACA79B',
        name: 'Bio Box mobiel toilet',
        isPrimary: false,
        formats: [
          {
            code: 'thumbnailD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/thumbnail-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'smallD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'small',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/small-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'mediumD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/medium-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'largeD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'large',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/large-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'onlineD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'online',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/online-Bio-Box-mobiel-toilet.png',
          },
        ],
      },
    ],
    isEcoLabel: false,
    isThirdPartyEquipment: false,
    isUniqueItem: false,
    itemNumber: '19002',
    quantity: 1,
    amount: 23.38,
    currency: 'EUR',
  },
  //biobox 3 2C874C82C8AC4519A95F865EF4E4BAFB
  '/customers/99993024/contractitems/2C874C82C8AC4519A95F865EF4E4BAFB': {
    id: '2C874C82C8AC4519A95F865EF4E4BAFB',
    contractNumber: '3110116336',
    debtorNumber: '99993024',
    type: 'Contract',
    status: 'Open',
    reference: '23/34015',
    orderedBy: 'Marije Engel',
    deliveryAddress: {
      street: 'Walstraat 11',
      zipcode: '6101 CG',
      city: 'Echt',
      country: 'NL',
    },
    executingDepot: {
      code: '311',
      name: 'Echt',
    },
    depot: {
      code: '311',
      name: 'Echt',
    },
    startDate: '2023-12-04',
    endDate: null,
    productName: 'Bio box complete',
    productNumber: '19002',
    productDescription:
      'The Bio Box is used as a temporary toilet facility. Ideal for construction projects, parties and events.',
    productImages: [
      {
        code: 'A373A2C7-BC13-4E1A-A7B0546F89832E8C',
        name: '19002',
        isPrimary: true,
        formats: [
          {
            code: 'thumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
          },
          {
            code: 'smallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'small',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
          },
          {
            code: 'primarySmallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'primarySmall',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
          },
          {
            code: 'mediumA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/medium-19002.jpg',
          },
          {
            code: 'largeA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'large',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/large-19002.jpg',
          },
          {
            code: 'onlineA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'online',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/online-19002.png',
          },
          {
            code: 'primaryThumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'primaryThumbnail',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
          },
        ],
      },
      {
        code: '878E3F40-0781-418B-89C9D447CCBF9291',
        name: 'Bio Box mobiel toilet',
        isPrimary: false,
        formats: [
          {
            code: 'thumbnail878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/thumbnail-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'small878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'small',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/small-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'medium878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/medium-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'large878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'large',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/large-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'online878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'online',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/online-Bio-Box-mobiel-toilet.png',
          },
        ],
      },
      {
        code: 'D991CD8F-5743-40CF-88384E60B6ACA79B',
        name: 'Bio Box mobiel toilet',
        isPrimary: false,
        formats: [
          {
            code: 'thumbnailD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/thumbnail-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'smallD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'small',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/small-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'mediumD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/medium-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'largeD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'large',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/large-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'onlineD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'online',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/online-Bio-Box-mobiel-toilet.png',
          },
        ],
      },
    ],
    isEcoLabel: false,
    isThirdPartyEquipment: false,
    isUniqueItem: false,
    itemNumber: '19002',
    quantity: 1,
    amount: 23.38,
    currency: 'EUR',
  },
  //biobox 4 86E445F509424495826435A076049062
  '/customers/99993024/contractitems/86E445F509424495826435A076049062': {
    id: '86E445F509424495826435A076049062',
    contractNumber: '3110116336',
    debtorNumber: '99993024',
    type: 'Contract',
    status: 'Open',
    reference: '23/34015',
    orderedBy: 'Marije Engel',
    deliveryAddress: {
      street: 'Walstraat 11',
      zipcode: '6101 CG',
      city: 'Echt',
      country: 'NL',
    },
    executingDepot: {
      code: '311',
      name: 'Echt',
    },
    depot: {
      code: '311',
      name: 'Echt',
    },
    startDate: '2023-12-04',
    endDate: null,
    productName: 'Bio box complete',
    productNumber: '19002',
    productDescription:
      'The Bio Box is used as a temporary toilet facility. Ideal for construction projects, parties and events.',
    productImages: [
      {
        code: 'A373A2C7-BC13-4E1A-A7B0546F89832E8C',
        name: '19002',
        isPrimary: true,
        formats: [
          {
            code: 'thumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
          },
          {
            code: 'smallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'small',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
          },
          {
            code: 'primarySmallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'primarySmall',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
          },
          {
            code: 'mediumA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/medium-19002.jpg',
          },
          {
            code: 'largeA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'large',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/large-19002.jpg',
          },
          {
            code: 'onlineA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'online',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/online-19002.png',
          },
          {
            code: 'primaryThumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'primaryThumbnail',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
          },
        ],
      },
      {
        code: '878E3F40-0781-418B-89C9D447CCBF9291',
        name: 'Bio Box mobiel toilet',
        isPrimary: false,
        formats: [
          {
            code: 'thumbnail878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/thumbnail-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'small878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'small',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/small-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'medium878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/medium-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'large878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'large',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/large-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'online878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'online',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/online-Bio-Box-mobiel-toilet.png',
          },
        ],
      },
      {
        code: 'D991CD8F-5743-40CF-88384E60B6ACA79B',
        name: 'Bio Box mobiel toilet',
        isPrimary: false,
        formats: [
          {
            code: 'thumbnailD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/thumbnail-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'smallD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'small',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/small-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'mediumD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/medium-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'largeD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'large',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/large-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'onlineD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'online',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/online-Bio-Box-mobiel-toilet.png',
          },
        ],
      },
    ],
    isEcoLabel: false,
    isThirdPartyEquipment: false,
    isUniqueItem: false,
    itemNumber: '19002',
    quantity: 1,
    amount: 23.38,
    currency: 'EUR',
  },
  //biobox 5 2963F98E9ED644D6AFBBD844BE0CE0EB
  '/customers/99993024/contractitems/2963F98E9ED644D6AFBBD844BE0CE0EB': {
    id: '2963F98E9ED644D6AFBBD844BE0CE0EB',
    contractNumber: '3110116336',
    debtorNumber: '99993024',
    type: 'Contract',
    status: 'Open',
    reference: '23/34015',
    orderedBy: 'Marije Engel',
    deliveryAddress: {
      street: 'Walstraat 11',
      zipcode: '6101 CG',
      city: 'Echt',
      country: 'NL',
    },
    executingDepot: {
      code: '311',
      name: 'Echt',
    },
    depot: {
      code: '311',
      name: 'Echt',
    },
    startDate: '2023-12-04',
    endDate: null,
    productName: 'Bio box complete',
    productNumber: '19002',
    productDescription:
      'The Bio Box is used as a temporary toilet facility. Ideal for construction projects, parties and events.',
    productImages: [
      {
        code: 'A373A2C7-BC13-4E1A-A7B0546F89832E8C',
        name: '19002',
        isPrimary: true,
        formats: [
          {
            code: 'thumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
          },
          {
            code: 'smallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'small',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
          },
          {
            code: 'primarySmallA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'primarySmall',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/small-19002.jpg',
          },
          {
            code: 'mediumA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/medium-19002.jpg',
          },
          {
            code: 'largeA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'large',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/large-19002.jpg',
          },
          {
            code: 'onlineA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'online',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/online-19002.png',
          },
          {
            code: 'primaryThumbnailA373A2C7-BC13-4E1A-A7B0546F89832E8C',
            type: 'primaryThumbnail',
            url: 'https://mediahub.boels.com/m/2e20cc12a2af3cdb/thumbnail-19002.jpg',
          },
        ],
      },
      {
        code: '878E3F40-0781-418B-89C9D447CCBF9291',
        name: 'Bio Box mobiel toilet',
        isPrimary: false,
        formats: [
          {
            code: 'thumbnail878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/thumbnail-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'small878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'small',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/small-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'medium878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/medium-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'large878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'large',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/large-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'online878E3F40-0781-418B-89C9D447CCBF9291',
            type: 'online',
            url: 'https://mediahub.boels.com/m/1d9f49c877c9081c/online-Bio-Box-mobiel-toilet.png',
          },
        ],
      },
      {
        code: 'D991CD8F-5743-40CF-88384E60B6ACA79B',
        name: 'Bio Box mobiel toilet',
        isPrimary: false,
        formats: [
          {
            code: 'thumbnailD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/thumbnail-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'smallD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'small',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/small-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'mediumD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/medium-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'largeD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'large',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/large-Bio-Box-mobiel-toilet.jpg',
          },
          {
            code: 'onlineD991CD8F-5743-40CF-88384E60B6ACA79B',
            type: 'online',
            url: 'https://mediahub.boels.com/m/a4c9583ec6b634c0/online-Bio-Box-mobiel-toilet.png',
          },
        ],
      },
    ],
    isEcoLabel: false,
    isThirdPartyEquipment: false,
    isUniqueItem: false,
    itemNumber: '19002',
    quantity: 1,
    amount: 23.38,
    currency: 'EUR',
  },
  // Electrical slide gate 46A2D69CA26945EB8793C9018E73EEA3
  '/customers/99993024/contractitems/46A2D69CA26945EB8793C9018E73EEA3': {
    id: '46A2D69CA26945EB8793C9018E73EEA3',
    contractNumber: '3360778345',
    debtorNumber: '99993024',
    type: 'Contract',
    status: 'Completed',
    reference: '896448-01.2023',
    orderedBy: 'Sasja van Hamersen',
    deliveryAddress: {
      street: 'Parkallee 36',
      zipcode: '41974',
      city: 'Ludwigsfelde',
      country: 'DE',
    },
    executingDepot: {
      code: '336',
      name: 'Münster',
    },
    depot: {
      code: '336',
      name: 'Münster',
    },
    startDate: '2023-01-07',
    endDate: '2023-06-30',
    productName: 'Electrical slide gate, 7 metres',
    productNumber: '13238',
    productDescription:
      "Basic system that you can use for all your painting, maintenance, renovation and installation jobs. Quick and easy to set up with a modular design that allows you to extend the ladder in height and width. You're well protected with the Safe-Quick Guardrail mobile scaffold, even before you access the platform. Unlike the wide freestanding scaffold, the wall remains completely untouched in this configuration. Suitable for both indoor and outdoor use.Maximum free-standing height of 8.2 m (work platform height) for outdoor use and 12.2 m for indoor use. If the mobile scaffold needs to be set up higher than these working heights, it must meet the same requirements as a fixed scaffold and therefore should be anchored in position.",
    productImages: [
      {
        code: '66298209-98BC-41E6-92CA5B37664B9124',
        name: 'Schuifpoort 230 V- 7 meter',
        isPrimary: true,
        formats: [
          {
            code: 'thumbnail66298209-98BC-41E6-92CA5B37664B9124',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/8263b4a181669269/thumbnail-Schuifpoort-230-V-7-meter.jpg',
          },
          {
            code: 'small66298209-98BC-41E6-92CA5B37664B9124',
            type: 'small',
            url: 'https://mediahub.boels.com/m/8263b4a181669269/small-Schuifpoort-230-V-7-meter.jpg',
          },
          {
            code: 'medium66298209-98BC-41E6-92CA5B37664B9124',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/8263b4a181669269/medium-Schuifpoort-230-V-7-meter.jpg',
          },
          {
            code: 'large66298209-98BC-41E6-92CA5B37664B9124',
            type: 'large',
            url: 'https://mediahub.boels.com/m/8263b4a181669269/large-Schuifpoort-230-V-7-meter.jpg',
          },
          {
            code: 'online66298209-98BC-41E6-92CA5B37664B9124',
            type: 'online',
            url: 'https://mediahub.boels.com/m/8263b4a181669269/online-Schuifpoort-230-V-7-meter.png',
          },
          {
            code: 'primaryThumbnail66298209-98BC-41E6-92CA5B37664B9124',
            type: 'primaryThumbnail',
            url: 'https://mediahub.boels.com/m/8263b4a181669269/thumbnail-Schuifpoort-230-V-7-meter.jpg',
          },
          {
            code: 'primarySmall66298209-98BC-41E6-92CA5B37664B9124',
            type: 'primarySmall',
            url: 'https://mediahub.boels.com/m/8263b4a181669269/small-Schuifpoort-230-V-7-meter.jpg',
          },
        ],
      },
      {
        code: '0A59507B-138D-44D9-B9D4275C18FC47D8',
        name: 'Schuifpoort 230 V- 7 meter',
        isPrimary: false,
        formats: [
          {
            code: 'thumbnail0A59507B-138D-44D9-B9D4275C18FC47D8',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/47d9854dcb9f17c8/thumbnail-Schuifpoort-230-V-7-meter.jpg',
          },
          {
            code: 'small0A59507B-138D-44D9-B9D4275C18FC47D8',
            type: 'small',
            url: 'https://mediahub.boels.com/m/47d9854dcb9f17c8/small-Schuifpoort-230-V-7-meter.jpg',
          },
          {
            code: 'medium0A59507B-138D-44D9-B9D4275C18FC47D8',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/47d9854dcb9f17c8/medium-Schuifpoort-230-V-7-meter.jpg',
          },
          {
            code: 'large0A59507B-138D-44D9-B9D4275C18FC47D8',
            type: 'large',
            url: 'https://mediahub.boels.com/m/47d9854dcb9f17c8/large-Schuifpoort-230-V-7-meter.jpg',
          },
          {
            code: 'online0A59507B-138D-44D9-B9D4275C18FC47D8',
            type: 'online',
            url: 'https://mediahub.boels.com/m/47d9854dcb9f17c8/online-Schuifpoort-230-V-7-meter.png',
          },
        ],
      },
    ],
    isEcoLabel: false,
    isThirdPartyEquipment: false,
    isUniqueItem: true,
    itemNumber: '13238',
    quantity: 1.0,
    quantityReturned: 0.0,
    amount: 1170.0,
    currency: 'EUR',
    accessories: [
      {
        id: 'DCAC4EFDF18C42C1B249F09765A933B3',
        status: 'Open',
        productName: 'Adaptor cable 230V, 16A',
        itemNumber: '12923',
        productNumber: '12923',
        isThirdPartyEquipment: false,
        quantity: 1.0,
      },
      {
        id: 'FEF9F62EFD5C4CE8849C763BBDC069AF',
        status: 'Open',
        productName: 'Extension cable 230V 3P-CEE 3*2.5 mm², 25 m',
        itemNumber: '14642',
        productNumber: '14642',
        isThirdPartyEquipment: false,
        quantity: 1.0,
      },
      {
        id: '92042889758E44D7B820FE588159DB59',
        status: 'Open',
        productName: 'Push-button panel',
        itemNumber: '15935',
        productNumber: '15935',
        isThirdPartyEquipment: false,
        quantity: 1.0,
      },
    ],
  },
  // Acrow prop 1.80 - 3.00 m 46A2D69CA26945EB8793C9018E73EEA3
  '/customers/99993024/contractitems/B5B6BF025E5944C3832B12062E376578': {
    id: 'B5B6BF025E5944C3832B12062E376578',
    contractNumber: '3360778345',
    debtorNumber: '99993024',
    type: 'Contract',
    status: 'Completed',
    reference: '896448-01.2023',
    orderedBy: 'Sasja van Hamersen',
    deliveryAddress: {
      street: 'Parkallee 36',
      zipcode: '41974',
      city: 'Ludwigsfelde',
      country: 'DE',
    },
    executingDepot: {
      code: '336',
      name: 'Münster',
    },
    depot: {
      code: '336',
      name: 'Münster',
    },
    startDate: '2023-01-07',
    endDate: '2023-06-30',
    productName: 'Acrow prop 1.80 - 3.00 m',
    productNumber: '14777',
    productImages: [
      {
        code: '920265BA-84D7-479A-86E864CC7AE381F2',
        name: 'PROG-605 - 14777',
        isPrimary: true,
        formats: [
          {
            code: 'thumbnail920265BA-84D7-479A-86E864CC7AE381F2',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/thumbnail-PROG-605-14777.jpg',
          },
          {
            code: 'primarySmall920265BA-84D7-479A-86E864CC7AE381F2',
            type: 'primarySmall',
            url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/small-PROG-605-14777.jpg',
          },
          {
            code: 'large920265BA-84D7-479A-86E864CC7AE381F2',
            type: 'large',
            url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/large-PROG-605-14777.jpg',
          },
          {
            code: 'small920265BA-84D7-479A-86E864CC7AE381F2',
            type: 'small',
            url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/small-PROG-605-14777.jpg',
          },
          {
            code: 'online920265BA-84D7-479A-86E864CC7AE381F2',
            type: 'online',
            url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/online-PROG-605-14777.png',
          },
          {
            code: 'medium920265BA-84D7-479A-86E864CC7AE381F2',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/medium-PROG-605-14777.jpg',
          },
          {
            code: 'primaryThumbnail920265BA-84D7-479A-86E864CC7AE381F2',
            type: 'primaryThumbnail',
            url: 'https://mediahub.boels.com/m/4313dba3161b2b0f/thumbnail-PROG-605-14777.jpg',
          },
        ],
      },
      {
        code: '36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
        name: 'Schroefstempel 150 - 250 cm',
        isPrimary: false,
        formats: [
          {
            code: 'thumbnail36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
            type: 'thumbnail',
            url: 'https://mediahub.boels.com/m/eb53a421a1484478/thumbnail-Schroefstempel-150-250-cm.jpg',
          },
          {
            code: 'small36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
            type: 'small',
            url: 'https://mediahub.boels.com/m/eb53a421a1484478/small-Schroefstempel-150-250-cm.jpg',
          },
          {
            code: 'medium36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
            type: 'medium',
            url: 'https://mediahub.boels.com/m/eb53a421a1484478/medium-Schroefstempel-150-250-cm.jpg',
          },
          {
            code: 'large36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
            type: 'large',
            url: 'https://mediahub.boels.com/m/eb53a421a1484478/large-Schroefstempel-150-250-cm.jpg',
          },
          {
            code: 'online36A294D2-5E48-4EA4-9E18FBAA81FE7ADD',
            type: 'online',
            url: 'https://mediahub.boels.com/m/eb53a421a1484478/online-Schroefstempel-150-250-cm.png',
          },
        ],
      },
    ],
    isEcoLabel: false,
    isThirdPartyEquipment: false,
    isUniqueItem: false,
    itemNumber: '14777',
    quantity: 178,
    quantityReturned: 0.0,
    amount: 24.2,
    currency: 'EUR',
  },

  '/requestpickups': {},
  '/preferences': {
    contactperson: {
      firstName: 'Demo',
      lastName: 'Account',
      salutation: 'Mr',
      language: 'en',
      addresses: [
        {
          type: 'postal',
          country: 'NL',
        },
      ],
      contactInfo: [
        {
          type: 'work',
          classification: 'email',
          value: 'demo@boels.com',
        },
      ],
    },
    companyInformation: {
      companyName: 'Demo Company',
      companySize: '25+',
      industry: 'Construction',
    },
    privacy: {
      hasOptedOutEmail: false,
      hasOptedOutPhone: false,
      hasOptedOutProcessing: false,
      hasOptedOutSolicit: false,
      hasOptedOutProfiling: false,
      hasOptedOutTracking: false,
    },
    consents: [
      {
        consentType: 'Commercial',
        isActive: true,
      },
    ],
    interests: [
      {
        category: 'Construction',
      },
      {
        category: 'PowerAirWater',
      },
    ],
    subscriptions: [
      {
        subscriptionType: 'ProductOffers',
        isActive: true,
      },
      {
        subscriptionType: 'RentalTalk',
        isActive: true,
      },
    ],
  },
};
